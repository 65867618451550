<template>
  <div class="form">
    <section class="form-section">
      <p class="form-headline">基本情報</p>
      <div :class="{ 'd-flex': hasOmiseNumber, 'form-row': !hasOmiseNumber && !hasAuthenticatedEvents }">
        <FormRow v-if="isEdit" style="flex-basis: 28%;">
          <template v-slot:label>user ID</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="row">
                <div class="col-6" :class="{ 'col-12': customerDetail.omiseNumber }">
                  <span v-if="customerDetail.hasSwitchCpm">{{ form.numberPut }}</span>
                  <span>{{ form.number }}</span>
                </div>
              </div>
            </div>
          </template>
        </FormRow>
        <FormRow v-if="hasOmiseNumber" style="flex: 1%;">
          <template v-slot:label>モバイル会員番号</template>
          <template v-slot:content>
            <div class="form-content-row">
              <div class="row">
                <div class="col-12" v-if="customerDetail.omiseNumber">{{ customerDetail.omiseNumber }}</div>
              </div>
            </div>
          </template>
        </FormRow>
      </div>
      <FormRow v-if="hasAuthenticatedEvents">
        <template v-slot:content>
          <TableWithCheckbox
            tableClass="table-with-padding"
            :dataTable="dataTable"
            :labels="labels"
            :hasStatusClass="false"
            :notOddBackgroundColor="true"
          ></TableWithCheckbox>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>
          お名前
          <i v-if="hasIdentification" class="ml-10 fz-30 aikon aikon-member"></i>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.nameSei }"
                  type="text"
                  name="nameSei"
                  placeholder="姓"
                  v-model="form.nameSei"
                  v-maxlength
                  :disabled="hasIdentification || isUseLinkageNecFlag"
                  maxlength="100"
                />
              </div>
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.nameMei }"
                  type="text"
                  name="nameMei"
                  placeholder="名"
                  v-model="form.nameMei"
                  v-maxlength
                  :disabled="hasIdentification || isUseLinkageNecFlag"
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>フリガナ</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.kanaSei }"
                  type="text"
                  name="kanaSei"
                  placeholder="セイ"
                  v-model="form.kanaSei"
                  :disabled="hasBankFlag || isUseLinkageNecFlag"
                  v-maxlength
                  v-katakana
                  maxlength="100"
                />
              </div>
              <div class="form-group-item">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.kanaMei }"
                  type="text"
                  name="kanaMei"
                  placeholder="メイ"
                  :disabled="hasBankFlag || isUseLinkageNecFlag"
                  v-model="form.kanaMei"
                  v-maxlength
                  v-katakana
                  maxlength="100"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>
          生年月日
          <i v-if="hasIdentification" class="ml-10 fz-30 aikon aikon-member"></i>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-icon form-date">
                  <Flatpickr
                    :class="{ 'form-control form-icon-input form-date-input': true, 'is-error': errors.birthday }"
                    name="birthday"
                    v-model="form.birthday"
                    :disabled="hasIdentification || isUseLinkageNecFlag"
                    :config="config.birthday"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow>
        <template v-slot:label>
          性別
          <i v-if="hasIdentification" class="ml-10 fz-30 aikon aikon-member"></i>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <ul class="form-segment">
              <li
                v-for="item in genderList"
                :key="item.index"
                :class="{ 'form-segment-item': true, 'is-error': errors.gender }"
              >
                <label class="form-segment-set">
                  <input
                    class="form-segment-input"
                    type="radio"
                    :disabled="hasIdentification || isUseLinkageNecFlag"
                    :value="item.value"
                    name="gender"
                    v-model="form.gender"
                  />
                  <span class="form-segment-label">{{ item.label }}</span>
                </label>
              </li>
            </ul>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>
          郵便番号
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.zipcode }"
                  type="tel"
                  name="zipcode"
                  :disabled="isUseLinkageNecFlag"
                  v-number
                  v-maxlength
                  v-model="form.zipcode"
                  maxlength="7"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>電話番号</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-3">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.telephone }"
                  type="tel"
                  name="telephone"
                  v-number
                  v-maxlength
                  :disabled="isUseLinkageNecFlag"
                  v-model="form.telephone"
                  maxlength="11"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <template v-if="hasIdentification">
        <template v-if="isDigital">
          <FormRow>
            <template v-slot:label>住所</template>
            <template v-slot:content>
              <p v-if="customerDetail.addressCivilios">{{ customerDetail.addressCivilios }}</p>
            </template>
          </FormRow>
        </template>
        <template v-else>
          <FormRow>
            <template v-slot:label>都道府県</template>
            <template v-slot:content>
              <p v-if="customerDetail.prefectureCivilios">{{ customerDetail.prefectureCivilios }}</p>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>市区町村</template>
            <template v-slot:content>
              <p v-if="customerDetail.municipalityCivilios">{{ customerDetail.municipalityCivilios }}</p>
            </template>
          </FormRow>
          <FormRow>
            <template v-slot:label>町域・番地・建物名等</template>
            <template v-slot:content>
              <p v-if="customerDetail.addressCivilios">{{ customerDetail.addressCivilios }}</p>
            </template>
          </FormRow>
        </template>
      </template>
    </section>
    <section class="form-section" v-if="isCpmActiveAndHasSwitchCard">
      <p class="form-headline">ログイン情報</p>
      <FormRow :required="isEmailPwdRequired">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.email }"
                  type="text"
                  name="email"
                  v-email
                  v-trim
                  :disabled="isUseLinkageNecFlag"
                  v-maxlength
                  maxlength="50"
                  v-model="form.email"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="!isEdit">
        <template v-slot:label>ログインパスワード</template>
        <template v-slot:subLabel>
          <span class="text-note">
            ※ログインパスワードは半角英数字を組み合わせ、8～20文字で設定してください。<br />
            また、半角記号（/ \ ¥ を除く）を含めても構いません。
          </span>
        </template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    v-trim
                    v-model="form.password"
                    :class="{ 'form-control form-password-input': true, 'is-error': errors.password }"
                    :type="pwdVisible ? 'text' : 'password'"
                    :disabled="isUseLinkageNecFlag"
                    name="password"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section" v-if="isEventSmsAndHasSwitchCard">
      <p class="form-headline">SMS認証情報</p>
      <FormRow>
        <template v-slot:label>携帯電話番号</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  :class="{ 'form-control': true, 'is-error': errors.mobilephone }"
                  class="form-control"
                  type="tel"
                  name="mobilephone"
                  v-trim
                  v-number
                  v-maxlength
                  maxlength="14"
                  :disabled="!!hasMobilephone || isUseLinkageNecFlag"
                  v-model="form.mobilephone"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <section class="form-section" v-if="isEdit">
      <p class="form-headline">登録日時</p>
      <FormRow>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="scrollX">
              <table class="listTable">
                <tbody>
                  <tr v-for="event in form.events" :key="event.index">
                    <th>{{ event.name }}</th>
                    <td class="listTable-fix">{{ event.createDate | fullDate }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </FormRow>
    </section>
    <Modal @close="closeModal('confirmDuplicateModal')" v-if="confirmDuplicateModal">
      <template v-slot:headline>確認</template>
      <template v-slot:body>
        <p class="description-duplicate-tel">
          同一の電話番号で登録があります。アクティベートしますか？
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button
              class="btn btn-white"
              type="button"
              @click="closeModal('confirmDuplicateModal')"
            >
              キャンセル
            </button>
          </li>
          <li class="listGrid-item">
            <button class="btn btn-main"
              type="button"
              :disabled="isUpdateCustomerCpm"
              @click="handleUpdateCustomerCpm(null, true)"
            >
              はい
            </button>
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
import { required, email, maxLength } from 'vuelidate/lib/validators';
import modal from '@/mixins/plugin/modal';
//mixins
import flatpickr from '@/mixins/plugin/flatpickr';
import error from '@/mixins/plugin/error';
import password from '@/mixins/plugin/password';
//components
import FormRow from '@/components/FormRow.vue';
import Modal from '@/components/Modal.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
//helpers
import { isPhoneNumber, onlyKana, isNotEmpty, isAlphanumAndSpecialWithMinMaxLength, isZipcode } from '@/helpers/validators';
import { formatLocalString, endline } from '@/helpers/formatData';
//constants
import { StatusConstants } from '@/constants/status';
import { EventConstants } from '@/constants/event';

export default {
  data() {
    return {
      form: {},
      confirmDuplicateModal: false,
      isUpdateCustomerCpm: false,
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
  },
  validations() {
    const form = {
      nameSei: { maxLength: maxLength(100) },
      nameMei: { maxLength: maxLength(100) },
      kanaSei: { onlyKana, maxLength: maxLength(100) },
      kanaMei: { onlyKana, maxLength: maxLength(100) },
      zipcode: { required, isZipcode },
      telephone: { required, isPhoneNumber },
      email: { email, maxLength: maxLength(50) },
      mobilephone: {},
    };
    if (!this.form.nameSei && !this.form.nameMei) {
      form.nameSei = { isNotEmpty };
      form.nameMei = { isNotEmpty };
    }
    if (!this.form.kanaSei && !this.form.kanaMei) {
      form.kanaSei = { isNotEmpty };
      form.kanaMei = { isNotEmpty };
    }
    if (this.isEdit) {
      form.password = { alphanumAndSpecialWithMinMaxLengthPassword: isAlphanumAndSpecialWithMinMaxLength(8, 20) };
    } else {
      form.password = { required, alphanumAndSpecialWithMinMaxLengthPassword: isAlphanumAndSpecialWithMinMaxLength(8, 20) };
    }
    if (this.isEmailPwdRequired) form.email = { required, email, maxLength: maxLength(50) };
    if (!this.isCpmActiveAndHasSwitchCard) {
      delete form.password;
      delete form.email;
    }
    return { form }
  },
  mixins: [error, password, flatpickr, modal],
  components: {
    FormRow,
    Flatpickr,
    Modal,
    TableWithCheckbox,
  },
  computed: {
    ...mapGetters({
      customerDetail: 'customer/customerDetail',
      useSms: 'event/useSms',
      identityVerification: 'event/identityVerification',
      parentEventDetail: 'event/parentEventDetail',
      useLinkageNecFlag: 'event/useLinkageNecFlag',
      isClientChild: 'auth/isClientChild',
      useTicketFlag: 'event/useTicketFlag',
    }),
    subdomain() {
      return this.$route.params.subdomain;
    },
    customerId() {
      return this.$route.params.customerId;
    },
    genderList() {
      return Object.keys(StatusConstants.gender).map((item) => {
        return {
          value: item,
          label: StatusConstants.gender[item],
        }
      });
    },
    isCpmActiveAndHasSwitchCard() {
      return !this.isEdit || !this.customerDetail.isCpm || (this.isActiveCpmUser && this.parentEventDetail.useSwitchCPMCard);
    },
    isEventSmsAndHasSwitchCard() {
      return this.useSms && this.isCpmActiveAndHasSwitchCard;
    },
    isEmailPwdRequired() {
      return !this.customerDetail.isCpm ? !this.isEventSmsAndHasSwitchCard : !(this.isActiveCpmUser && this.parentEventDetail.useSwitchCPMCard);
    },
    isActiveCpmUser() {
      return this.customerDetail?.kanaMei && this.customerDetail?.kanaSei && this.customerDetail?.nameMei && this.customerDetail?.nameSei
        && this.customerDetail?.telephone && this.customerDetail?.zipcode;
    },
    hasIdentification() {
      if (this.customerDetail.isCpm) return false;
      return this.isEdit && !!this.customerDetail?.identityFlag && this.identityVerification;
    },
    hasOmiseNumber() {
      return this.isEdit && !!this.customerDetail?.omiseNumber;
    },
    hasMobilephone() {
      return this.isEdit && this.customerDetail?.mobilephone;
    },
    hasBankFlag() {
      return this.isEdit && this.customerDetail?.bankFlag;
    },
    isMethodIdentified() {
      return this.isEdit && this.customerDetail?.methodIdentity == EventConstants.partnerIdentityType.civilios.value;
    },
    isUseLinkageNecFlag() {
      if (this.customerDetail.isCpm) return false;
      return this.isEdit && this.useLinkageNecFlag;
    },
    isDigital() {
      return this.parentEventDetail?.partnerIdentity === StatusConstants.identityType.digital;
    },
    labels() {
      return [
        { key: 'name', name: 'イベント名', tdClass: 'pre-line font-bold dataTable-break', },
        { key: 'registId', name: '登録ID' },
        { key: 'chargeLimit', name: '残高に保持できる金額の上限', tdClass: 'text-right', thClass: 'text-right' },
        { key: 'purchaseAmountLimit', name: '1人あたりの累計購入金額', tdClass: 'text-right', thClass: 'text-right' },
      ];
    },
    hasAuthenticatedEvents() {
      if (!this.isEdit || this.useTicketFlag) return false;
      return !this.isClientChild && this.customerDetail.authenticatedEvents?.length;
    },
    dataTable() {
      return (this.customerDetail?.authenticatedEvents || []).map(item => ({
        name: endline(item.name, 10),
        registId: item.registId,
        chargeLimit: item?.purchaseAmountLimit && item?.rate ? (formatLocalString(item.purchaseAmountLimit * item.rate) + '円') : '',
        purchaseAmountLimit: item?.purchaseAmountLimit ? (formatLocalString(item.purchaseAmountLimit) + '円') : '',
      }));
    }
  },
  watch: {
    async customerDetail(val) {
      if (val && this.isEdit) {
        if (val?.isCommonPoint || val?.isPrivatePoint) {
          await this.dispatchAction('parentPoint/getCustomerPointActivities', {
            limit: 50000,
            customerId: this.customerId
          });
        }
      }
    }
  },
  methods: {
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.form, 'customer');
      } else {
        let result;
        if (this.isEdit) {
          if (this.customerDetail?.isCpm) {
            const data = await this.dispatchAction('customer/checkDuplicateTel', {
              customerId: this.customerDetail?.number,
              telephone: this.form.telephone
            });
            if (data?.duplicate) {
              if (!this.isActiveCpmUser) {
                this.showModal('confirmDuplicateModal');
              } else {
                this.$message.showMessageError(data.error);
              }
            } else {
              this.handleUpdateCustomerCpm(result, false);
            }
          } else {
            result = await this.dispatchAction('customer/updateCustomer', {
              customerId: this.customerId,
              ...this.form,
              isCustomerTesting: this.customerDetail?.isCustomerTesting,
            });
            if (result) {
              await this.$router.push({ name: 'EventUser' });
              this.$message.updated('customer');
            }
          }
        } else {
          result = await this.dispatchAction('customer/createCustomer', {
            ...this.form,
            isCustomerTesting: this.customerDetail?.isCustomerTesting,
            toCertification: 'email'
          });
          if (result) {
            await this.$router.push({ name: 'EventUser' });
            this.$message.created('customer');
          }
        }
      }
    },
    async getDetail() {
      if (this.isEdit) {
        await this.dispatchAction('customer/getCustomerDetail', {
          customerId: this.customerId,
        });
      }
    },
    async getActivities() {
      if (this.isEdit) {
        await this.dispatchAction('customer/getCustomerActivities', {
          limit: 100,
          customerId: this.customerId
        });
      }
    },
    async handleUpdateCustomerCpm(result = null, closeModal = false) {
      this.isUpdateCustomerCpm = true;
      if (!this.isCpmActiveAndHasSwitchCard) {
        delete this.form?.password;
        delete this.form?.email;
      }
      result = await this.dispatchAction('customer/updateCustomerCpm', {
        customerId: this.customerDetail?.number,
        ...this.form,
      });
      if (result) {
        await this.$router.push({ name: 'EventUser' });
        this.$message.updated('customer');
        if (closeModal) this.closeModal('confirmDuplicateModal');
        this.isUpdateCustomerCpm = false;
      }
    },
    dispatchAction(actionPath, payload) {
      return this.$store.dispatch(actionPath, {
        subdomain: this.subdomain,
        ...payload,
      })
    },
  },
  mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getActivities(),
      this.$store.dispatch('event/getParentEventDetail', this.subdomain),
      this.getDetail(),
    ]).finally(() => {
      if (this.isEdit) {
        this.form = { ...this.customerDetail };
      } else {
        this.form = { ...this.form, mobilephone: '' };
      }
      this.$loading.clear(loading);
    })
  },
}
</script>

<style scoped>
.custom-aikon {
  width: 32px;
  height: 32px;
}
</style>
